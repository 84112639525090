.blog {
  padding-top: 2rem;
  font-family: var(--font-base);
  letter-spacing: 0.04em;
  line-height: 28px;
  font-style: italic;
  text-align: start;
  font-size: var(--fontSize-posts);
  padding-top: 5rem;
}
.blog .posts {
  width: 90%;
  display: block;
  margin: 20px auto;
  position: relative;
  box-shadow: 10px 5px 5px rgba(0, 0, 0, 0.526);
}
.blog .posts > .front {
  background-color: #71bdff;
}
.blog .posts > .back {
  position: absolute;
  top: 0px;
  left: 0px;
}
.blog .posts > .front, .blog .posts > .back {
  height: 300px;
  width: 100%;
  padding: 20px;
  display: block;
  border-radius: 6px;
  transition-duration: 1s;
  transition-property: transform, opacity;
  font-size: var(--fontSize-post);
}

.horizontal > .front {
  opacity: 1;
  transform: rotateY(0deg);
  text-align: center;
  font-size: var(--fontSize-post);
}
.horizontal > .back {
  opacity: 0;
  transform: rotateY(180deg);
}
.horizontal:hover > .front {
  opacity: 0;
  transform: rotateY(180deg);
}
.horizontal:hover > .back {
  opacity: 1;
  transform: rotateY(0deg);
}

.vertical > .front {
  opacity: 1;
  transform: rotateX(0deg);
}
.vertical > .back {
  opacity: 0;
  transform: rotateX(180deg);
}
.vertical:hover > .front {
  opacity: 0;
  transform: rotateX(180deg);
}
.vertical:hover > .back {
  opacity: 1;
  transform: rotateX(0deg);
}

.post-img {
  width: 100%;
  height: 200px;
}

.post__btn {
  padding: 1rem;
}

.post__date {
  text-align: end;
}

.btn_detail {
  margin: 20px 0;
}

.post {
  text-align: end;
}/*# sourceMappingURL=Posts.css.map */