.contact {
    padding-top: 3rem;
    background-color: var(--color-secondary);
    font-family: var(--font-alt);
    font-weight: 400;
    font-size: var(--fontSize-secondary);
    font-style: italic;
}

.contact-container {
    width: 20px;
    height: 100%;
    display: contents;
}

.contact-container-wrapper {
    padding-right: 10px;
    display: flex;
}

.contact-container-wrapper-left {
    flex: 1;
    margin-left: 30px;
    padding-top: 4rem;
}

.contact-container-wrapper-left-title {
    font-size: var(--fontSize-primary);
    width: 80%;
    margin-left: 60px;
    font-style: initial;
}

.contact-container-wrapper-left-title-item {
    display: flex;
    align-items: center;
    margin: 50px 20px;
    width: 70%;
}

.contact-container-wrapper-left-title-item i {
    margin-right: 40px;
}

.contact-container-wrapper-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 4rem;
}

.contact-container-wrapper-right-desc {
    color: var(--color-black);
    margin: 0 30px;
}

.contact-container-wrapper-right form {
    margin: 20px;
}

.contact-container-wrapper-right form input {
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
    border: none;
    border-bottom: 1px solid black;
    font-size: var(--fontSize-secondary);
    padding-left: 10px;
}

.contact-container-wrapper-right form textarea {
    width: 100%;
    margin: 10px 0px;
    font-size: var(--fontSize-secondary);
    padding-left: 10px;
}

.contact-container-wrapper-right form .btn {
    font-size: var(--fontSize-secondary);
    padding: 12px;
    background-color: var(--color-primary);
    color: var(--color-white);
    border-radius: 1rem;
    border: none;
    margin-bottom: 10px;
}

.contact-container-wrapper-right form .btn:hover {
    font-size: var(--fontSize-secondary);
    background-color: var(--color-primary);
    color: var(--color-white);
    box-shadow: 0 5px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

@media screen and (max-width: 750px) {
    .contact-container-wrapper {
        flex-direction: column;
    }

    .contact {
        padding-top: 2rem;
    }
}

@media screen and (max-width: 600px) {
    .contact-container-wrapper {
        flex-direction: column;
    }
}

@media screen and (max-width: 480px) {
    .contact-container-wrapper {
        flex-direction: column;
        margin-left: 10px;
    }

    .contact-container-wrapper-left-title {
        font-size: var(--fontSize-primary);
    }

    .contact-container-wrapper-left-title-item {
        margin: 20px 0px;
        width: 100%;
    }

    .contact-container-wrapper-right-desc {
        display: none;
    }

    .contact-container-wrapper-right form {
        margin-top: 0px;
        display: flex;
        flex-wrap: wrap;
    }

    .contact-container-wrapper-right form input {
        width: 100%;
        height: 40px;
        margin-left: 0;
    }

    .contact-container-wrapper-right form button {
        margin-top: 10px;
    }
}