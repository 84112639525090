.app__chef-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2rem;
}

.app__chef-content-quote {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.app__chef-content-quote img {
  width: 47px;
  height: 40px;
  margin: 0 1rem 1rem 1rem;
}

.app__chef-sign {
  width: 100%;
}

.app__chef-sign p:first-child {
  font-family: var(--font-base);
  font-weight: 400;
  font-size: 32;
  line-height: 41px;
  letter-spacing: 0.04em;
  color: var(--color-golden);
}

.app__chef-sign img {
  width: 250px;
}

@media screen and (max-width: 2000px) {
  .app__chef-sign img {
    width: 370px;
  }
  .app__personal-links{
      padding: 0 8rem;
  }
}

@media screen and (max-width: 1050px) {
  .app__personal-links {
    flex-direction: column;
    padding: 0;
  }
}

@media screen and (max-width: 450px) {
  .app__chef-sign img {
  }
}

.personal {
  shape-outside: url("./../../assets/baka1.png");
}

.app__personal-links {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* align-items: flex-start; */
  margin-top: 1rem;

}

.app__personal-links_work {
  flex: 1 1;
  /* margin: 1rem; */
  text-align: center;
}

.app__personal-links_work p:nth-child(2n + 1) {
  margin-bottom: 1rem;
}

.app__personal-headtext {
  font-family: var(--font-base);
  color: var(--color-black);
  font-weight: 400;
  letter-spacing: 0.04em;
  /* text-transform: capitalize; */
  font-size: 26px;
  line-height: 41.6px;
  margin-bottom: 1rem;
  margin-top: 2rem;
  text-align: start;
}
