.app__navbar {
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--color-secondary);
  padding: 0 2rem;
  z-index: 100;
}

.app__navbar-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.app__navbar-logo img {
  width: 150px;
}

.app__navbar-links {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
}

.app__navbar-links li {
  margin: 0 1rem;
  cursor: pointer;
}

.app__navbar-links li:hover {
  color: var(--color-grey);
}

.app__navbar-login {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.app__navbar-login a {
  margin: 0 1rem;
  text-decoration: none;
  transition: 0.5s ease;
}

.app__navbar-login a:hover {
  border-bottom: 1px solid var(--color-primary);
}

.app__navbar-login div {
  width: 1px;
  height: 30px;
  background: var(--color-grey);
}

.app__navbar-smallscreen {
  display: none;
}

.app__navbar-smallscreen_links {
  list-style: none;
}

.app__navbar-smallscreen_links li {
  margin: 2rem;
  cursor: pointer;
  color: var(--color-primary) !important;
  font-size: var(--fontSize-secondary);
  text-align: center;
  font-family: var(--font-base);
}

.app__navbar-smallscreen_links li:hover {
  color: var(--color-white);
}

select {
  text-align: center;
}

.app__navbar-smallscreen_links select {
  cursor: pointer;
  color: var(--color-primary);
  font-size: var(--fontSize-secondary);
  font-family: var(--font-base);
}

.app__navbar-smallscreen_links select > option {
  cursor: pointer;
  color: var(--color-white) !important;
  font-size: var(--fontSize-secondary);
  font-family: var(--font-base);
}

.app__navbar-smallscreen_overlay {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100vh;
  background: var(--color-black);
  color: var(--color-white) !important;
  transition: 0.5s ease;
  flex-direction: column;
  z-index: 5;
}

.app__navbar-smallscreen_overlay .overlay__close {
    font-size: var(--fontSize-primary);
      color: var(--color-primary);
      cursor: pointer;
      position: absolute;
      /* top: 20px; */
      right: 20px;
      height: 100px;
}

@media screen and (max-width: 2000px) {
  .app__navbar-logo img {
    width: 210px;
  }
}

@media screen and (max-width: 1150px) {
  .app__navbar-links {
    display: none;
  }

  .app__navbar-smallscreen {
    display: flex;
  }
}

@media screen and (max-width: 650px) {
  .app__navbar-logo img {
    width: 110px;
  }

  .app__navbar {
    padding: 1rem;
    position: fixed;
  }

  .app__navbar-login {
    display: none;
  }
}
