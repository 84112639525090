@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap');

:root {
  --font-base: "Nunito Sans", sans-serif;
  --font-alt: 'Nunito Sans', sans-serif;

  --color-primary: #39AEFB;
  --color-secondary: #0687dd;
  --color-black: #000000;
  --color-grey: rgb(7, 7, 7);
  --color-white: #ffffff;
  --fontSize-primary: 36px;
  --fontSize-secondary: 20px;
  --fontSize-posts: 12px;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

a {
  color: unset;
  text-decoration: none;
  display: inline-flex;
}

i {
  display: inline-flex;
}

.slide-bottom {
  -webkit-animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
    transform: translateY(-25%);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
    transform: translateY(-25%);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}