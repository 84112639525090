.blog {
  padding-top: 2rem;
  font-family: var(--font-base);
  letter-spacing: 0.04em;
  line-height: 28px;
  font-style: italic;
  text-align: start;
  font-size: var(--fontSize-posts);
  padding-top: 5rem;

  .posts {
    width: 90%;
    display: block;
    margin: 20px auto;
    position: relative;
    box-shadow: 10px 5px 5px rgba(0, 0, 0, 0.526);

    &>.front {
      background-color: #71bdff;
    }

    &>.back {
      position: absolute;
      top: 0px;
      left: 0px;
    }

    &>.front,
    &>.back {
      height: 300px;
      width: 100%;
      padding: 20px;
      display: block;
      border-radius: 6px;
      transition-duration: 1s;
      transition-property: transform, opacity;
      font-size: var(--fontSize-post);
    }
  }
}

// horizontal transition
.horizontal {
  &>.front {
    opacity: 1;
    transform: rotateY(0deg);
    text-align: center;
    font-size: var(--fontSize-post);
  }

  &>.back {
    opacity: 0;
    transform: rotateY(180deg);
  }

  &:hover {
    &>.front {
      opacity: 0;
      transform: rotateY(180deg);
    }

    &>.back {
      opacity: 1;
      transform: rotateY(0deg);
    }
  }
}

// vertical transition
.vertical {
  &>.front {
    opacity: 1;
    transform: rotateX(0deg);
  }

  &>.back {
    opacity: 0;
    transform: rotateX(180deg);
  }

  &:hover {
    &>.front {
      opacity: 0;
      transform: rotateX(180deg);
    }

    &>.back {
      opacity: 1;
      transform: rotateX(0deg);
    }
  }
}

.post-img {
  width: 100%;
  height: 200px;
}

.post__btn {
  padding: 1rem;
}

.post__date {
  text-align: end;
}

.btn_detail {
  margin: 20px 0;
}

.post {
  text-align: end;
}