.box {
    position: relative;
    /* width: 380px;
    height: 500px; */
    background-color: var(--color-white);
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    margin: 0px auto;
}

.box__form {
    position: absolute;
    inset: 2px;
    border-radius: 8px;
    z-index: 1;
    padding: 5px 40px;
    display: flex;
    flex-direction: column;
    margin-top: 60px;
}

.box__form h2 {
    color: var(--primary);
    font-weight: 800;
    text-align: center;
    letter-spacing: 5px;
}

.box__form--input-box {
    position: relative;
    /* width: 300px; */
    margin-top: 35px;
    min-height: 50px;
}

.box__form--input-box input {
    position: relative;
    width: 100%;
    padding: 20px 10px 10px;
    margin-top: 10px;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: var(--fontSize-secondary);
    letter-spacing: 2px;
    z-index: 2;
}

.box__form--input-box input:valid~label,
.box__form--input-box input:focus~label {
    color: var(--white);
    font-size: var(--fontSize-secondary);
    transform: translateX(0px) translateY(-34px);
}

.box__form--input-box label {
    position: absolute;
    left: 0;
    padding: 20px 0px 10px;
    font-size: var(--fontSize-secondary);
    color: var(--color-secondary);
    pointer-events: none;
    transition: 0.5s;
}

.box__form--input-box i {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: var(--color-primary);
    border-radius: 4px;
    transition: 0.5s;
    pointer-events: none;
    z-index: 1;
}

.box__form--input-box input:valid~i,
.box__form--input-box input:focus~i {
    height: 44px;
}

.box__form--link {
    margin: 10px 0;
}

.box__form--link a {
    color: var(--color-grey);
    text-decoration: none;
}

.box__form--link a:hover {
    color: var(--color-secondary);
}

.box__form input[type=submit] {
    border: none;
    outline: none;
    background-color: var(--color-primary);
    padding: 10px 25px;
    width: 120px;
    margin-top: 10px;
    border-radius: 4px;
    font-weight: 800;
    font-size: var(--fontSize-secondary);
    cursor: pointer;
}

.box__form input[type=submit]:active {
    opacity: 0.8;
}

.box::before {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    width: 380px;
    height: 420px;
    background: linear-gradient(0deg, transparent, #2150dc, #2117d8);
    transform-origin: bottom right;
    animation: animate 6s linear infinite;
}

.box::after {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    width: 380px;
    height: 420px;
    background: linear-gradient(0deg, transparent, var(--color-primary), var(--color-primary));
    transform-origin: bottom right;
    animation: animate 6s linear infinite;
    animation-delay: -3s;
}

@keyframes animate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}