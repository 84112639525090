.section__padding {
  padding: 0 6rem;
}

.flex__center {
  justify-content: center;
  align-items: center;
}

.app__bg {
  background: url("./assets/bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  background-attachment: fixed;
}

.app__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: url(./assets/bg.png);
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  background-attachment: fixed;
  padding-top: 2rem;
  /* padding-bottom: 2rem; */
}

.app__wrapper_info {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding-top: 5rem;
}

.app__wrapper_img {
  flex: 1;
  width: 100%;
  /* display: flex; */
  justify-content: center;
  text-align: center;  
}

.app__wrapper_img-reverse {
  justify-content: flex-start;
  margin-right: 2rem;
}

.app__wrapper_img img {
  width: 280px;
}

.custom__button {
  background-color: var(--color-primary);
  color: var(--color-black);
  font-family: var(--font-base);
  font-weight: 700;
  /* margin: 40px; */
  letter-spacing: 0.04em;
  line-height: 28px;
  font-size: var(--fontSize-secondary);
  padding: 0.5rem 1.5rem;
  border-radius: 1px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.5s ease;
}

.custom__button:hover {
  background-color: var(--color-secondary);
  transform: scale(1.1);
  box-shadow: 0 5px 10px var(--color-black);
}

.p__cormorant {
  font-family: var(--font-base);
  font-weight: 700;
  letter-spacing: 0.04em;
  font-feature-settings: "tnum" on, "lnum" on;
  line-height: 29.9px;
  font-size: var(--fontSize-secondary);
}

.p__opensans {
  font-family: var(--font-alt);
  font-weight: 400;
  letter-spacing: 0.04em;
  line-height: 28px;
  font-size: var(--fontSize-secondary);
  font-style: italic;
  color: black !important;
  text-align: start;
  /* padding-top: 2rem; */
  max-width: 90%;
  
}

.select__opensans {
  background: transparent;
  font-family: var(--font-alt);
  color: var(--color-black);
  font-weight: 400;
  letter-spacing: 0.04em;
  line-height: 28px;
  font-size: var(--fontSize-secondary);
  border: none;
}

.select__opensans option {
  background: var(--color-primary);
  font-family: var(--font-alt);
  color: var(--color-grey);
  font-weight: 400;
  letter-spacing: 0.04em;
  line-height: 28px;
  font-size: var(--fontSize-secondary);
  border: none;
  outline: none;
}

.swimming_img {
  width: 45px;
}

@media screen and (min-width: 2000px) {

  .custom__button,
  .p__cormorant {
    font-size: var(--fontSize-secondary);
    line-height: 67px;
    margin-bottom: 30px;
  }

  .p__opensans {
    font-size: var(--fontSize-primary);
    line-height: 50px;
  }

  .headtext__cormorant {
    font-size: 70px;
    line-height: 210px;
  }

  .swimming_img {
    width: 80px;
  }
}

@media screen and (max-width: 1150px) {
  .app__wrapper {
    flex-direction: column;
  }

  .app__wrapper_img-reverse {
    margin: 0 0 5rem 0;
  }

  .app__wrapper_img img {
    /* width: 100%; */
  }
}

@media screen and (max-width: 850px) {
  .section__padding {
    /* padding: 4rem; */
    background-color: var(--font-white);
  }
}

@media screen and (max-width: 650px) {
  .section__padding {
    padding: 1rem 2rem;
  }

  .p__cormorant {
    font-size: 21px;
  }

  .p__opensans {
    /* padding-bottom: 1rem; */
    font-size: var(--fontSize-secondary);
  }

}

@media screen and (max-width: 450px) {

  h1,
  p {
    text-align: var(--fontSize-secondary);
  }

  .p__opensans {
    font-size: var(--fontSize-secondary);
    text-align: start;
  }

  .p__cormorant {
    font-size: var(--fontSize-secondary);
  }

  .headtext__cormorant {
    font-size: 25px;
    font-family: var(--font-base);
  }

  .app__wrapper_img img {
    width: 180px;
  }

  .app__header-h1 {
    font-size: 28px;
  }
}