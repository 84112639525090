.app__aboutus {
    padding-top: 3rem;
}

.app__heading {
    /* display: inline-flex; */
    /* padding: 0 0 0 15px; */
}

.app__aboutus-overlay {
    position: absolute;
    inset: 0;
}

.app__aboutus-overlay img {
    width: 391px;
    height: 415px;
    z-index: 0;
}

.app__aboutus-content {
    width: 100%;
    z-index: 2;
    display: flex;
    /* padding-top: 4rem; */
}

.app__aboutus-content_about {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: left;
}

.app__aboutus-content_swimm {
    margin: 2rem 4rem;
    text-align: center;
}

.app__aboutus-content_swimm img {
    height: 910px;
}

.app__aboutus-content_history {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    text-align: left;
    margin-top: 1rem;
}

.app__aboutus-content_about p,
.app__aboutus-content_history p {
    margin-bottom: 1rem;
    color: var(--color-grey);
}

@media screen and (max-width: 2000px) {
    .app__aboutus-content_swimm img {
        height: 400px;
    }
}

@media screen and (max-width: 1200px) {
    .app__aboutus-content {
        flex-direction: column;
        margin-top: 50px;
    }

    .app__aboutus-content_swimm {
        margin: 4rem 0;
    }
}

@media screen and (max-width: 650px) {
    .app__aboutus-overlay img {
        width: 80%;
        height: 320px;
    }

    .app__aboutus-content_about p,
    .app__aboutus-content_history p {
        text-align: start;
    }
}