.app__header {
    background: var(--color-white);
    padding-top: 1rem;
    /* padding-bottom: 2rem; */
}

.app__header-h1 {
    font-family: var(--font-base);
    color: var(--color-primary);
    letter-spacing: 0.04em;
    text-transform: uppercase;
    line-height: 60px;
    font-size: var(--fontSize-primary);
    font-style: italic;
}

@media screen and (max-width: 450px) {
    .app__header-h1 {
        font-size: 28px;
    }
}

.licence {

    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.app__wrapper_i {
    /* margin-top: 4rem; */
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin: 0rem !important;
}

@media screen and (max-width: 650px) {
    .app__wrapper_i{
        padding-top: 5rem;
    }
}
