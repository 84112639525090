.admin {
    display: flex;
    flex-wrap: wrap;
    display: grid;
    height: 90vh;
    grid-template-rows: 70px 1fr 70px;
    grid-template-columns: 250px 1fr;
    grid-template-areas: "header header" "nav    main" "footer footer";
}

.admin__header {
    display: flex;
    flex-basis: 100%;
    grid-area: header;
    height: 80px;
    background-color: var(--color-primary);
    position: relative;
}

.admin__nav {
    flex: 0 0 250px;
    grid-area: nav;
}

.admin__footer {
    display: flex;
    grid-area: footer;
    flex-basis: 100%;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    padding: 0 var(--spacing);
    color: var(--color-primary);
    background-color: var(--color-grey);
}

@media screen and (min-width: 48rem) {
    .admin {
        --spacing: 2rem;
    }
}

.toolbar {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    padding: 0 var(--spacing);
}

.card {
    height: 100%;
    padding: 1rem;
font-size: var(--fontSize-primary);    font-weight: 300;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.btn {
    display: inline-block;
    border-radius: 5em;
    border: 0;
    padding: 0.5rem 1rem;
    white-space: nowrap;
}

.btn--primary {
    background-color: var(--color-white);
    color: var(--color-black);
    font-family: var(--font-base);
    font-weight: 700;
    letter-spacing: 0.04em;
    line-height: 28px;
font-size: var(--fontSize-secondary);
    padding: 0.5rem 1.5rem;
    border-radius: 1px;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.5s ease;
}

.btn--primary:hover {
    background-color: var(--color-secondary);
    transform: scale(1.1);
    box-shadow: 0 5px 10px var(--color-black);
}

.ticker {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
}

.ticker__item {
    margin-right: 1rem;
}